/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const calculateRisk = /* GraphQL */ `mutation CalculateRisk($responses: [Response]) {
  calculateRisk(responses: $responses) {
    rating
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CalculateRiskMutationVariables,
  APITypes.CalculateRiskMutation
>;
