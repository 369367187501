/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getQuestions = /* GraphQL */ `query GetQuestions {
  getQuestions {
    id
    text
    answers {
      id
      text
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetQuestionsQueryVariables,
  APITypes.GetQuestionsQuery
>;
